import { graphql } from "gatsby";
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";

import { Heading2, Heading6 } from "@src/components/elements/headings";
import { CardPair } from "@src/components/post/card";
import { Title } from "@src/components/post/typography";
import { Tag } from "@src/components/post/tag";
import Footer from "@src/components/footer";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  width: 100%;
`;
const Body = styled.div`
  width: ${(props: ThemeProviderProps) => props.theme.dimensions.maxWidth};
  margin: auto;
  word-break: break-word;
  max-width: 100%;
  box-sizing: border-box;
`;

const Ends = styled.div`
  color: ${(props: ThemeProviderProps) => props.theme.color.Selection};
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Body};
  max-width: ${(props: ThemeProviderProps) =>
    props.theme.dimensions.contentWidth};
  width: 98%;
  margin: 1em auto 4rem auto;
  font-size: 1em;
  border-radius: 3px;
  text-transform: uppercase;
  text-align: left;
`;

const TagContainer = styled.div`
  margin: 0.2rem auto 4rem auto;
  text-align: center;
`;
const BodyWrapper = styled.div`
  margin: 4rem 0 0 0;
`;

export default function Post(props) {
  const {
    data: {
      mdx: {
        code,
        frontmatter: { title, subtitle, hideTitle, tags },
      },
    },
    pageContext: { next, prev },
  } = props;

  return (
    <Flex>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Body>
        {!hideTitle && (
          <>
            <Title>{title}</Title>
            <Heading2>{subtitle}</Heading2>
          </>
        )}
        <BodyWrapper>
          <MDXRenderer {...props}>{code.body}</MDXRenderer>
        </BodyWrapper>
        <Ends>&mdash; OK</Ends>
      </Body>
      <Heading6>Tagged</Heading6>
      <TagContainer>
        {tags && tags.map(t => <Tag name={t} key={t} />)}
      </TagContainer>
      <CardPair
        prev={
          prev && {
            node: prev,
          }
        }
        next={
          next && {
            node: next,
          }
        }
      />
      <Footer />
    </Flex>
  );
}

/**
 * Query for data for the page. Note that $id is injected in via context from
 * actions.createPage. See gatsby-node.js for more info.
 */
export const pageQuery = graphql`
  query DocsLayoutQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        subtitle
        hideTitle
        date
        tags
      }
      code {
        body
      }
    }
  }
`;
